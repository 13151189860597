import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";

const Header = () => {
    const { authenticated, logout } = useAuth();

    if (!authenticated) return null;

    return (
        <div className="border-b-gray-200  border-b-2 py-3 mb-4 px-5 bg">
            <div className="grid grid-cols-12 max-w-6xl mx-auto">
                <h1 className="text-left col-span-8 sm:col-span-4 content-center grid text-xl">
                    <Link to="/">
                        <img src="/images/depenable-logo-condensed.png" alt="depenable-logo-condensed.png" className="inline-block h-14" />
                    </Link>
                </h1>
                <a href="/work-ticket-status" className="col-span-4 sm:col-span-2 text-center py-2">Work ticket status ↗️</a>
                <button className="border p-2 col-span-4 sm:col-span-2 sm:col-end-13 rounded-full bg-slate-200 focus:ring-2 ring-offset-2 ring-black/30" onClick={logout}>
                    Logout
                </button>
            </div>
        </div>
    );
};

export default Header;
